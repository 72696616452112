'use client';

import { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import Link from 'next/link';
import {usePathname, useRouter} from 'next/navigation';
import { routes } from '@/helpers/routes';
import { gsap } from 'gsap';
import { HiOutlineMenu, HiOutlineX } from 'react-icons/hi';
import Footer from "@/components/layout/footer";
import Cookies from "js-cookie";
import {API_URL, TG_TOKEN} from "@/helpers/variables";
import {fetchData} from "@/helpers/general-functions";
import {useDispatch, useSelector} from "react-redux";
import {setUserData} from "@/redux/sclices/user-data-slice";
import {FaRegUser} from "react-icons/fa";
import {IoIosArrowDown, IoIosLogOut} from "react-icons/io";
import {TbShoppingCartCheck} from "react-icons/tb";
import {RiPassPendingLine} from "react-icons/ri";

const Header = () => {
    const pathname = usePathname();
    const router = useRouter();
    const dispatch = useDispatch();
    const mobileMenuRef = useRef(null);
    const profileDropDownRef = useRef(null);
    const profileDropDownRefMobile = useRef(null);
    const profileDropDownRefMobileMenu = useRef(null);
    const [profileDropDownOpen, setProfileDropDownOpen] = useState(false);
    const [profileDropDownOpenMobile, setProfileDropDownOpenMobile] = useState(false);
    const [profileDropDownOpenMobileMenu, setProfileDropDownOpenMobileMenu] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    let loginCheckLoading = useSelector((state) => state.userData?.loginCheckLoading);
    let isUserLoggedIn = useSelector((state) => state.userData?.isLoggedIn);
    let userData = useSelector((state) => state.userData?.info);

    console.log(useSelector((state) => state.userData));

    // GSAP animation for mobile menu
    useEffect(() => {
        if (isMobileMenuOpen) {
            gsap.to(mobileMenuRef.current, {
                duration: 0.3,
                x: 0,
                opacity: 1,
                ease: 'power2.out',
            });
        } else {
            gsap.to(mobileMenuRef.current, {
                duration: 0.3,
                x: '100%',
                opacity: 0.5,
                ease: 'power2.in',
            });
        }
    }, [isMobileMenuOpen]);

    // Close mobile menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                mobileMenuRef.current &&
                !mobileMenuRef.current.contains(event.target) &&
                isMobileMenuOpen
            ) {
                setIsMobileMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMobileMenuOpen]);

    // Close profile menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileDropDownRef.current &&
                !profileDropDownRef.current.contains(event.target) &&
                profileDropDownOpen
            ) {
                setProfileDropDownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [profileDropDownOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileDropDownRefMobile.current &&
                !profileDropDownRefMobile.current.contains(event.target) &&
                profileDropDownOpenMobile
            ) {
                setProfileDropDownOpenMobile(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [profileDropDownOpenMobile]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileDropDownRefMobileMenu.current &&
                !profileDropDownRefMobileMenu.current.contains(event.target) &&
                profileDropDownOpenMobileMenu
            ) {
                setProfileDropDownOpenMobileMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [profileDropDownOpenMobileMenu]);

    useEffect(() => {
        if (profileDropDownOpen) {
            // Open animation
            gsap.to(profileDropDownRef.current, {
                height: 'auto',
                opacity: 1,
                duration: 0.5,
                ease: 'power2.out',
            });
        } else {
            // Close animation
            gsap.to(profileDropDownRef.current, {
                height: 0,
                opacity: 0,
                duration: 0.5,
                ease: 'power2.in',
            });
        }
    }, [profileDropDownOpen]);

    useEffect(() => {
        if (profileDropDownOpenMobile) {
            // Open animation
            gsap.to(profileDropDownRefMobile.current, {
                height: 'auto',
                opacity: 1,
                duration: 0.5,
                ease: 'power2.out',
            });
        } else {
            // Close animation
            gsap.to(profileDropDownRefMobile.current, {
                height: 0,
                opacity: 0,
                duration: 0.5,
                ease: 'power2.in',
            });
        }
    }, [profileDropDownOpenMobile]);

    useEffect(() => {
        if (profileDropDownOpenMobileMenu) {
            // Open animation
            gsap.to(profileDropDownRefMobileMenu.current, {
                height: 'auto',
                opacity: 1,
                duration: 0.5,
                ease: 'power2.out',
            });
        } else {
            // Close animation
            gsap.to(profileDropDownRefMobileMenu.current, {
                height: 0,
                opacity: 0,
                duration: 0.5,
                ease: 'power2.in',
            });
        }
    }, [profileDropDownOpenMobileMenu]);

    const toggleDropdown = () => {
        setProfileDropDownOpen((prev) => !prev);
        setProfileDropDownOpenMobile((prev) => !prev);
        setProfileDropDownOpenMobileMenu((prev) => !prev);
    };

    const handelLogout = async () => {
        Cookies.remove(TG_TOKEN);

        dispatch(
            setUserData({
                loginCheckLoading: false,
                isLoggedIn: false,
                info: {},
            })
        );

        await router.refresh();
    };

    return (
        <header className="fixed top-0 w-full bg-tg-bg-header py-4 md:py-[29px] border-b border-tg-border z-[9999]">
            <div className="container flex items-center justify-between">
                {/* Logo */}
                <a href='/'>
                    <div className="h-8 xl:h-12 relative w-auto aspect-[182/48]">
                        <Image
                            src="/images/tripgate-logo.png"
                            layout="fill"
                            objectFit="contain"
                            alt="TripGate Logo"
                        />
                    </div>
                </a>

                {/* Desktop Navigation */}
                <nav className="hidden lg:flex items-center gap-8 text-sm xl:text-base">
                    <div className="flex items-center gap-8">
                        {routes?.map((route) => (
                            <Link href={route?.path} key={route?.id}>
                                <span className={`/${pathname?.split('/')[1]}` === (route?.path) ? 'text-tg-text-title' : 'text-tg-text-normal hover:text-tg-text-important transition duration-150'}>
                                    {route?.label}
                                </span>
                            </Link>
                        ))}
                    </div>

                    <span className="text-tg-border"> | </span>

                    {loginCheckLoading ? (
                        <SkeletonTheme baseColor="#01405F33" highlightColor="#141A1F99">
                            <Skeleton width={'43px'} height={'24px'} borderRadius={'0.375rem'}/>
                            <Skeleton width={'170px'} height={'60px'} borderRadius={'1rem'} containerClassName={'!-mt-3 !-mb-2'}/>
                        </SkeletonTheme>
                        )
                        :
                        (isUserLoggedIn ? (
                            <div className={`relative flex items-center gap-2 cursor-pointer text-tg-text-important hover:text-tg-text-title transition duration-150 ${profileDropDownOpen && 'text-tg-text-title'}`} onClick={toggleDropdown}>
                                <div className="relative flex items-center justify-center w-[48px] h-[48px] rounded-full border border-tg-border-profile bg-transparent p-[3px]">
                                    <div className="flex items-center justify-center bg-tg-background w-full h-full rounded-full">
                                        <FaRegUser />
                                    </div>
                                    <span className='w-2.5 h-2.5 rounded-full bg-tg-bg-login-badge absolute top-0.5 right-0.5 border border-[#06141B]'/>
                                </div>
                                <span>
                                    <IoIosArrowDown />
                                </span>

                                <div ref={profileDropDownRef}
                                     className="absolute top-14 text-tg-text-normal text-sm whitespace-nowrap right-0 p-4 flex flex-col bg-tg-bg-box border border-tg-border rounded-2xl h-0 opacity-0 overflow-hidden">
                                    <span className="flex items-center gap-2 pb-2 border-b border-tg-border text-tg-text-important cursor-auto">
                                        {userData?.first_name ? `${userData?.first_name} ${userData?.last_name}` : userData?.email}
                                    </span>
                                    <Link href="/profile">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border hover:text-tg-text-important transition duration-150">
                                            <RiPassPendingLine/>
                                            Your Profile
                                        </span>
                                    </Link>
                                    <Link href="/profile/orders">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border hover:text-tg-text-important transition duration-150">
                                            <TbShoppingCartCheck/>
                                            Previous Orders
                                        </span>
                                    </Link>
                                    <Link href="/profile?changePass=true">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border stroke-tg-text-normal hover:stroke-tg-text-important hover:text-tg-text-important transition duration-150">
                                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path
                                                    d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                                      strokeWidth="1.5" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M16 19V5" strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path
                                                    d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                                      strokeWidth="1.5" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path
                                                    d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                            Change Password
                                        </span>
                                    </Link>
                                    <span className="flex items-center gap-2 pt-2 text-tg-bg-login-badge hover:text-tg-bg-btn-delete transition duration-150" onClick={handelLogout}>
                                        <IoIosLogOut />
                                        Log out
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <Link href="/login">
                                    <span
                                        className={pathname?.startsWith('/login') ? 'text-tg-text-title' : 'text-tg-text-normal hover:text-tg-text-important transition duration-150'}>
                                      Login
                                    </span>
                                </Link>
                                <Link href="/signup">
                                    <span
                                        className={`bg-tg-bg-btn py-5 px-14 rounded-2xl ${pathname?.startsWith('/signup') ? 'text-tg-text-title' : 'text-tg-text-important hover:text-tg-text-normal transition duration-150'}`}>
                                      Sign Up
                                    </span>
                                </Link>
                            </>
                        ))
                    }
                </nav>

                {/* Mobile Menu Button */}
                <div className="flex items-center gap-3 lg:hidden">
                    {loginCheckLoading ? (
                            <SkeletonTheme baseColor="#01405F33" highlightColor="#141A1F99">
                                <Skeleton width={'30px'} height={'30px'} borderRadius={'100%'} containerClassName={'!-mt-3 !-mb-2'}/>
                            </SkeletonTheme>
                        )
                        :
                        (isUserLoggedIn ? (
                            <div
                                className={`relative flex items-center gap-1 cursor-pointer text-tg-text-important hover:text-tg-text-title transition duration-150 ${profileDropDownOpen && 'text-tg-text-title'}`}
                                onClick={toggleDropdown}>
                                <span>
                                    <IoIosArrowDown/>
                                </span>
                                <div
                                    className="relative flex items-center justify-center w-[30px] h-[30px] rounded-full border border-tg-border-profile bg-transparent p-[3px]">
                                    <div
                                        className="flex items-center justify-center bg-tg-background w-full h-full rounded-full text-xs">
                                        <FaRegUser/>
                                    </div>
                                    <span className='w-2 h-2 rounded-full bg-tg-bg-login-badge absolute top-0 right-0 border border-[#06141B]'/>
                                </div>

                                <div ref={profileDropDownRefMobile}
                                     className="absolute top-10 text-tg-text-normal text-sm whitespace-nowrap -right-8 p-4 flex flex-col bg-tg-bg-box border border-tg-border rounded-2xl h-0 opacity-0 overflow-hidden">
                                    <span
                                        className="flex items-center gap-2 pb-2 border-b border-tg-border text-tg-text-important cursor-auto">
                                        {userData?.first_name ? `${userData?.first_name} ${userData?.last_name}` : userData?.email}
                                    </span>
                                    <Link href="/profile">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border hover:text-tg-text-important transition duration-150">
                                            <RiPassPendingLine/>
                                            Your Profile
                                        </span>
                                    </Link>
                                    <Link href="/profile/orders">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border hover:text-tg-text-important transition duration-150">
                                            <TbShoppingCartCheck/>
                                            Previous Orders
                                        </span>
                                    </Link>
                                    <Link href="/profile?changePass=true">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border stroke-tg-text-normal hover:stroke-tg-text-important hover:text-tg-text-important transition duration-150">
                                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path
                                                    d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                                      strokeWidth="1.5" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M16 19V5" strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path
                                                    d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                                      strokeWidth="1.5" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path
                                                    d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                            Change Password
                                        </span>
                                    </Link>
                                    <span
                                        className="flex items-center gap-2 pt-2 text-tg-bg-login-badge hover:text-tg-bg-btn-delete transition duration-150"
                                        onClick={handelLogout}>
                                        <IoIosLogOut/>
                                        Log out
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <Link href='/login'>
                                <div
                                    className="lg:hidden relative flex items-center justify-center w-[30px] h-[30px] rounded-full border border-tg-border-profile bg-transparent p-[3px]">
                                    <div className="flex items-center justify-center bg-tg-background w-full h-full rounded-full text-xs">
                                        <FaRegUser/>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                    <button
                        className="lg:hidden text-tg-text-title text-xl focus:outline-none"
                        onClick={() => setIsMobileMenuOpen(true)}
                        aria-label="Open Menu"
                    >
                        <HiOutlineMenu/>
                    </button>
                </div>
            </div>

            {/* Mobile Navigation Menu */}
            <div
                ref={mobileMenuRef}
                className="fixed top-0 right-0 h-full bg-tg-bg-header z-[9998] transform translate-x-full lg:hidden flex flex-col shadow-lg w-full">
                <div className=" p-4 border-b border-tg-border">
                    {/* Logo in Mobile Menu */}
                    <div className="container flex items-center justify-between">
                        <div className="h-8 relative w-auto aspect-[182/48]">
                            <Image
                                src="/images/tripgate-logo.png"
                                layout="fill"
                                objectFit="contain"
                                alt="TripGate Logo"
                            />
                        </div>
                        {/* Close Button */}
                        <button
                            className="text-tg-text-title text-xl focus:outline-none"
                            onClick={() => setIsMobileMenuOpen(false)}
                            aria-label="Close Menu"
                        >
                            <HiOutlineX/>
                        </button>
                    </div>
                </div>

                <div className="text-base bg-tg-bg-box h-full">
                    <nav className="flex flex-col items-center gap-9 py-8 container">
                        {routes?.map((route) => (
                            <Link href={route?.path} key={route?.id}>
                                <span
                                    className={`py-2 ${
                                        pathname?.split('/')[0] === (route?.path)
                                            ? 'text-tg-text-title'
                                            : 'text-tg-text-normal hover:text-tg-text-important transition duration-150'
                                    }`}
                                    onClick={() => setIsMobileMenuOpen(false)}
                                >
                                    {route?.label}
                                </span>
                            </Link>
                        ))}

                        <span className='h-0 border-t border-tg-border w-28'/>

                        {loginCheckLoading ? (
                            <div className="flex items-center gap-4">
                                <SkeletonTheme baseColor="#01405F33" highlightColor="#141A1F99">
                                    <Skeleton width={'48px'} height={'48px'} borderRadius={'100%'} containerClassName={'!-mt-3 !-mb-2'}/>
                                    <Skeleton width={'40vw'} height={'24px'} borderRadius={'0.375rem'} containerClassName={'!-mt-3 !-mb-2'}/>
                                    <Skeleton width={'16px'} height={'10px'} borderRadius={'0.125rem'} containerClassName={'!-mt-3 !-mb-2'}/>
                                </SkeletonTheme>
                            </div>
                            )
                            :
                            (isUserLoggedIn ? (
                                <div
                                    className={`relative flex items-center gap-4 cursor-pointer text-tg-text-important hover:text-tg-text-title transition duration-150 ${profileDropDownOpenMobileMenu && 'text-tg-text-title'}`}
                                    onClick={toggleDropdown}>
                                    <div className="relative flex items-center justify-center w-[48px] h-[48px] rounded-full border border-tg-border-profile bg-transparent p-[3px]">
                                        <div
                                            className="flex items-center justify-center bg-tg-background w-full h-full rounded-full text-xs">
                                            <FaRegUser/>
                                        </div>
                                        <span className='w-2.5 h-2.5 rounded-full bg-tg-bg-login-badge absolute top-0.5 right-0.5 border border-[#06141B]'/>
                                    </div>
                                    <span className="-mt-1.5">
                                        {userData?.first_name ? `${userData?.first_name} ${userData?.last_name}` : userData?.email}
                                    </span>
                                    <span>
                                        <IoIosArrowDown/>
                                    </span>

                                    <div ref={profileDropDownRefMobileMenu}
                                         className="absolute top-14 text-tg-text-normal text-sm whitespace-nowrap right-0 p-4 flex flex-col bg-tg-bg-box border border-tg-border rounded-2xl h-0 opacity-0 overflow-hidden">
                                    <span
                                        className="flex items-center gap-2 pb-2 border-b border-tg-border text-tg-text-important cursor-auto">
                                        {userData?.first_name ? `${userData?.first_name} ${userData?.last_name}` : userData?.email}
                                    </span>
                                        <Link href="/profile">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border hover:text-tg-text-important transition duration-150">
                                            <RiPassPendingLine/>
                                            Your Profile
                                        </span>
                                        </Link>
                                        <Link href="/profile/orders">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border hover:text-tg-text-important transition duration-150">
                                            <TbShoppingCartCheck/>
                                            Previous Orders
                                        </span>
                                        </Link>
                                        <Link href="/profile?changePass=true">
                                        <span
                                            className="flex items-center gap-2 py-2 border-b border-tg-border stroke-tg-text-normal hover:stroke-tg-text-important hover:text-tg-text-important transition duration-150">
                                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path
                                                    d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                                      strokeWidth="1.5" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M16 19V5" strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path
                                                    d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                                      strokeWidth="1.5" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path
                                                    d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                                    strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                            Change Password
                                        </span>
                                        </Link>
                                        <span
                                            className="flex items-center gap-2 pt-2 text-tg-bg-login-badge hover:text-tg-bg-btn-delete transition duration-150"
                                            onClick={handelLogout}>
                                        <IoIosLogOut/>
                                        Log out
                                    </span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Link href="/login">
                                        <span
                                            className={`py-2 ${
                                                pathname?.startsWith('/login')
                                                    ? 'text-tg-text-title'
                                                    : 'text-tg-text-normal hover:text-tg-text-important transition duration-150'
                                            }`}
                                            onClick={() => setIsMobileMenuOpen(false)}
                                        >
                                        Login
                                    </span>
                                    </Link>
                                    <Link href="/signup">
                                        <span
                                            className={`bg-tg-bg-btn py-5 px-14 rounded-2xl ${pathname?.startsWith('/signup') ? 'text-tg-text-title' : 'text-tg-text-important hover:text-tg-text-normal transition duration-150'}`}
                                            onClick={() => setIsMobileMenuOpen(false)}
                                        >
                                            Sign Up
                                        </span>
                                    </Link>
                                </>
                            ))
                        }
                    </nav>
                </div>

                <Footer/>
            </div>
        </header>
    );
};

export default Header;
