import Link from "next/link";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="w-full bg-tg-bg-header py-[20px] border-t border-tg-border">
            <div className="container flex flex-col-reverse lg:flex-row items-center justify-between gap-2 lg:gap-0 text-xs font-semibold">
                <span className="text-tg-text-normal">
                    copyright ©{currentYear} Tripgate LLC. All rights reserved.
                </span>
                <span className="text-tg-text-title">
                    Washington DC, USA.
                </span>
                <span className="flex items-center gap-[2px] lg:w-[288px] justify-end">
                    <span className="text-tg-text-normal">
                        Contact Us:
                    </span>
                    <Link href='mailto:info@tripgate.us'>
                        <span className="text-tg-text-title">
                            info@tripgate.us
                    </span>
                    </Link>
                </span>
            </div>
        </footer>
    );
};

export default Footer;
